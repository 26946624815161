<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row>

                    <v-col cols="12" sm="6" v-for="(moduleDefinition, index) in modules" :key="index">
                        <v-card outlined>
                            <v-card-title><v-icon class="mr-2">{{ moduleDefinition.icon }}</v-icon> {{ moduleDefinition.name
                            }}
                                <v-spacer></v-spacer>

                                <v-btn-toggle tile group v-if="moduleDisplay.includes(moduleDefinition.name)">
                                    <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on"
                                                :value="[selectedCustomer.organisationId, selectedCustomer.relatedOrganisationId]"
                                                @click="applyAllModule(moduleDefinition.name, [selectedCustomer.organisationId, selectedCustomer.relatedOrganisationId])">
                                                <v-icon>groups</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>All Parties</span>
                                    </v-tooltip> -->

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" :value="[selectedCustomer.organisationId]"
                                                @click="applyAllModule(moduleDefinition.name, [selectedCustomer.organisationId])">
                                                <v-avatar size="36" v-if="selectedCustomer.organisation.logo"
                                                    style="background: white">
                                                    <v-img contain :src="selectedCustomer.organisation.logo"></v-img>
                                                </v-avatar>
                                                <v-icon color="blue" v-else>business</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ selectedCustomer.organisation.name }}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" :value="[selectedCustomer.relatedOrganisationId]"
                                                @click="applyAllModule(moduleDefinition.name, [selectedCustomer.relatedOrganisationId])">
                                                <v-avatar size="36" v-if="selectedCustomer.relatedOrganisation.logo"
                                                    style="background: white">
                                                    <v-img contain :src="selectedCustomer.relatedOrganisation.logo"></v-img>
                                                </v-avatar>
                                                <v-icon color="orange" v-else>business</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ selectedCustomer.relatedOrganisation.name }}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" :value="[]" @click="applyAllModule(moduleDefinition.name, null)" icon color="grey">
                                                <v-icon>cancel</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Not Applicable</span>
                                    </v-tooltip>
                                </v-btn-toggle>
                                <v-btn icon v-if="moduleDisplay.includes(moduleDefinition.name)"
                                    @click="moduleDisplay.splice(moduleDisplay.indexOf(moduleDefinition.name), 1)"><v-icon>expand_less</v-icon>
                                </v-btn>
                                <v-btn icon v-else
                                    @click="moduleDisplay.push(moduleDefinition.name)"><v-icon>expand_more</v-icon> </v-btn>

                            </v-card-title>
                            <v-card-subtitle v-if="moduleDefinition.description">{{ moduleDefinition.description
                            }}</v-card-subtitle>
                            <v-card-text>
                                <v-expand-transition>
                                    <v-list dense v-if="moduleDisplay.includes(moduleDefinition.name)">
                                        <div v-for="(submodule, subIndex) in moduleDefinition.submodules" :key="subIndex">
                                            <v-divider></v-divider>
                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-chip>{{ subIndex + 1 }}</v-chip>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title style="font-size: 16px">
                                                        {{ submodule.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle v-if="submodule.description">
                                                        {{ submodule.description }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider></v-divider>
                                            <v-list-item style="height: 65px"
                                                v-for="(field, fieldIndex) in submodule.fields" :key="fieldIndex">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ field.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="text-wrap" v-if="field.description">
                                                        {{ field.description }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    
                                                    <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" class="mr-0" text
                                                                :color="field.value == selectedCustomer.organisationId ? 'blue' : field.value == selectedCustomer.relatedOrganisationId ? 'orange' : 'grey'">
                                                                <v-avatar size="36"
                                                                    v-if="field.value == selectedCustomer.organisationId && selectedCustomer.organisation.logo"
                                                                    style="background: white">
                                                                    <v-img contain
                                                                        :src="selectedCustomer.organisation.logo"></v-img>
                                                                </v-avatar>
                                                                <v-avatar size="36"
                                                                    v-else-if="field.value == selectedCustomer.relatedOrganisationId && selectedCustomer.relatedOrganisation.logo"
                                                                    style="background: white">
                                                                    <v-img contain
                                                                        :src="selectedCustomer.relatedOrganisation.logo"></v-img>
                                                                </v-avatar>
                                                                <v-icon v-else-if="field.value">business</v-icon>
                                                                <v-icon v-else>cancel</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item @click="field.value = selectedCustomer.organisationId">
                                                                <v-list-item-action>
                                                                    <v-avatar size="30"
                                                                        v-if="selectedCustomer.organisation.logo"
                                                                        style="background: white">
                                                                        <v-img contain
                                                                            :src="selectedCustomer.organisation.logo"></v-img>
                                                                    </v-avatar>
                                                                    <v-icon v-else color="blue">business</v-icon>

                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                       {{ selectedCustomer.organisation.name }}
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>

                                                            <v-list-item @click="field.value = selectedCustomer.relatedOrganisationId">
                                                                <v-list-item-action  class="text-center">
                                                                    <v-avatar size="30"
                                                                        v-if="selectedCustomer.relatedOrganisation.logo"
                                                                        style="background: white">
                                                                        <v-img contain
                                                                            :src="selectedCustomer.relatedOrganisation.logo"></v-img>
                                                                    </v-avatar>
                                                                    <v-icon v-else color="orange">business</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                       {{ selectedCustomer.relatedOrganisation.name }}
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>

                                                            <v-list-item @click="field.value = null">
                                                                <v-list-item-action class="text-center">
                                                                    <v-icon color="grey">cancel</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                      Not Applicable
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>

                                                    <!-- <v-btn-toggle v-model="field.value" rounded>
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn small v-on="on"
                                                                    :value="[selectedCustomer.organisationId, selectedCustomer.relatedOrganisationId]">
                                                                    <v-icon>groups</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>All Parties</span>
                                                        </v-tooltip>

                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn small v-on="on"
                                                                    :value="[selectedCustomer.organisationId]">
                                                                    <v-avatar size="36"
                                                                        v-if="selectedCustomer.organisation.logo"
                                                                        style="background: white">
                                                                        <v-img contain
                                                                            :src="selectedCustomer.organisation.logo"></v-img>
                                                                    </v-avatar>
                                                                    <v-icon color="blue" v-else>business</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>{{ selectedCustomer.organisation.name }}</span>
                                                        </v-tooltip>

                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn small v-on="on"
                                                                    :value="[selectedCustomer.relatedOrganisationId]">
                                                                    <v-avatar size="36"
                                                                        v-if="selectedCustomer.relatedOrganisation.logo"
                                                                        style="background: white">
                                                                        <v-img contain
                                                                            :src="selectedCustomer.relatedOrganisation.logo"></v-img>
                                                                    </v-avatar>
                                                                    <v-icon color="orange" v-else>business</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>{{ selectedCustomer.relatedOrganisation.name }}</span>
                                                        </v-tooltip>

                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn small v-on="on" :value="[]">
                                                                    N/A
                                                                </v-btn>
                                                            </template>
                                                            <span>Not Applicable</span>
                                                        </v-tooltip>
                                                    </v-btn-toggle> -->

                                                </v-list-item-action>
                                            </v-list-item>
                                        </div>
                                    </v-list>
                                </v-expand-transition>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-card outlined>
                            <v-card-title><v-icon class="mr-2">description</v-icon> Documentation <v-spacer></v-spacer>
                                <v-btn-toggle group tile v-if="moduleDisplay.includes('Documentation')">
                                    <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on"
                                                :value="[selectedCustomer.organisationId, selectedCustomer.relatedOrganisationId]"
                                                @click="applyAllModule('Documentation', [selectedCustomer.organisationId, selectedCustomer.relatedOrganisationId])">
                                                <v-icon>groups</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>All Parties</span>
                                    </v-tooltip> -->

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" :value="[selectedCustomer.organisationId]"
                                                @click="applyAllModule('Documentation', selectedCustomer.organisationId)">
                                                <v-avatar size="36" v-if="selectedCustomer.organisation.logo"
                                                    style="background: white">
                                                    <v-img contain :src="selectedCustomer.organisation.logo"></v-img>
                                                </v-avatar>
                                                <v-icon color="blue" v-else>business</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ selectedCustomer.organisation.name }}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" :value="[selectedCustomer.relatedOrganisationId]"
                                                @click="applyAllModule('Documentation', selectedCustomer.relatedOrganisationId)">
                                                <v-avatar size="36" v-if="selectedCustomer.relatedOrganisation.logo"
                                                    style="background: white">
                                                    <v-img contain :src="selectedCustomer.relatedOrganisation.logo"></v-img>
                                                </v-avatar>
                                                <v-icon color="orange" v-else>business</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ selectedCustomer.relatedOrganisation.name }}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" :value="null" @click="applyAllModule('Documentation', null)" icon color="grey">
                                               <v-icon>cancel</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Not Applicable</span>
                                    </v-tooltip>
                                </v-btn-toggle>
                                <v-btn icon v-if="moduleDisplay.includes('Documentation')"
                                    @click="moduleDisplay.splice(moduleDisplay.indexOf('Documentation'), 1)"><v-icon>expand_less</v-icon>
                                </v-btn>
                                <v-btn icon v-else @click="moduleDisplay.push('Documentation')"><v-icon>expand_more</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-progress-linear indeterminate color="primary"
                                    v-if="loadingDocumentation"></v-progress-linear>
                                <v-expand-transition style="max-height: 50vh; overflow-y: auto">

                                    <v-list v-if="moduleDisplay.includes('Documentation')" dense
                                        :key="exportDocumentationKey">
                                        <v-list-item style="height: 65px" v-for="(document) in exportDocumentation"
                                            :key="document.id">
                                            <v-list-item-content>
                                                <v-list-item-title class="text-wrap">
                                                    {{ document.name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" class="mr-0" text
                                                                :color="document.value == selectedCustomer.organisationId ? 'blue' : document.value == selectedCustomer.relatedOrganisationId ? 'orange' : 'grey'">
                                                                <v-avatar size="36"
                                                                    v-if="document.value == selectedCustomer.organisationId && selectedCustomer.organisation.logo"
                                                                    style="background: white">
                                                                    <v-img contain
                                                                        :src="selectedCustomer.organisation.logo"></v-img>
                                                                </v-avatar>
                                                                <v-avatar size="36"
                                                                    v-else-if="document.value == selectedCustomer.relatedOrganisationId && selectedCustomer.relatedOrganisation.logo"
                                                                    style="background: white">
                                                                    <v-img contain
                                                                        :src="selectedCustomer.relatedOrganisation.logo"></v-img>
                                                                </v-avatar>
                                                                <v-icon v-else-if="document.value">business</v-icon>
                                                                <v-icon v-else>cancel</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item @click="document.value = selectedCustomer.organisationId">
                                                                <v-list-item-action>
                                                                    <v-avatar size="30"
                                                                        v-if="selectedCustomer.organisation.logo"
                                                                        style="background: white">
                                                                        <v-img contain
                                                                            :src="selectedCustomer.organisation.logo"></v-img>
                                                                    </v-avatar>
                                                                    <v-icon v-else color="blue">business</v-icon>

                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                       {{ selectedCustomer.organisation.name }}
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>

                                                            <v-list-item @click="document.value = selectedCustomer.relatedOrganisationId">
                                                                <v-list-item-action  class="text-center">
                                                                    <v-avatar size="30"
                                                                        v-if="selectedCustomer.relatedOrganisation.logo"
                                                                        style="background: white">
                                                                        <v-img contain
                                                                            :src="selectedCustomer.relatedOrganisation.logo"></v-img>
                                                                    </v-avatar>
                                                                    <v-icon v-else color="orange">business</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                       {{ selectedCustomer.relatedOrganisation.name }}
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>

                                                            <v-list-item @click="document.value = null">
                                                                <v-list-item-action class="text-center">
                                                                    <v-icon color="grey">cancel</v-icon>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                      Not Applicable
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-expand-transition>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    props: ['selectedCustomer'],
    data: () => ({
        modules: [
            {
                name: 'Bookings', icon: 'confirmation_number', submodules: [
                    {
                        name: 'Shipping Lines', fields: [
                            { name: 'Creation', field: 'Booking_Shipping_Line_Creation', value: [] },
                            { name: 'Confirmation', field: 'Booking_Shipping_Line_Confirmation', value: [] },
                        ]
                    },
                    {
                        name: 'Haulage', description: 'From Loading Point to Port', fields: [
                            { name: 'Creation', field: 'Booking_Haulage_PoiPort_Creation', value: [] },
                            { name: 'Confirmation', field: 'Booking_Haulage_PoiPort_Confirmation', value: [] },
                        ]
                    },
                    {
                        name: 'Haulage', description: 'From Point to Point', fields: [
                            { name: 'Creation', field: 'Booking_Haulage_PoiPoi_Creation', value: [] },
                            { name: 'Confirmation', field: 'Origin_Booking_Haulage_PoiPoi_Confirmation', value: [] },
                        ]
                    }
                ]
            },
            {
                name: 'Stock Management', icon: 'widgets', submodules: [
                    {
                        name: 'Packhouse', fields: [
                            { name: 'Loadout Instruction', field: 'Stock_Packhouse_Loadout', value: [] },
                            { name: 'Q67', field: 'Stock_Packhouse_Q67', value: [] },
                            { name: 'Container Terminal Order (CTO)', field: 'Stock_Packhouse_CTO', value: [] },

                        ]
                    },
                    {
                        name: 'Coldstore', fields: [
                            { name: 'Loadout Instruction', field: 'Stock_Coldstore_Loadout', value: [] },
                            { name: 'Q67', field: 'Stock_Coldstore_Q67', value: [] },
                            { name: 'Container Terminal Order (CTO)', field: 'Stock_Coldstore_CTO', value: [] },

                        ]
                    },
                ]
            }
        ],
        moduleDisplay: [],
        moduleKey: 100,
        exportDocumentation: [],
        exportDocumentationKey: 200,
        loadingDocumentation: false,
        roles: []
    }),
    computed: {
        availableParties() {
            let result = [
                this.selectedCustomer.relatedOrganisation,
                this.selectedCustomer.organisation
            ]
            return result
        }
    },
    watch: {
        'selectedCustomer.relatedOrganisation': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.getExportDocumentation()
                    this.getRelationshipResponsibilities()
                }
            }
        },
    },
    methods: {
        applyAllModule(moduleName, value) {
            if (moduleName == 'Documentation') {
                this.exportDocumentation.forEach(x => {
                    x.value = value
                })
                this.exportDocumentationKey++
                this.update
            } else {
                let findModule = this.modules.find(x => x.name == moduleName)
                findModule.submodules.forEach(x => {
                    x.fields.forEach(y => {
                        y.value = value
                    })
                })
                this.moduleKey++
            }

        },
        async getExportDocumentation() {
            this.loadingDocumentation = true
            this.exportDocumentation = await this.$API.getOrganisationExportDocumentation(this.selectedCustomer.relatedOrganisation.id)
            this.exportDocumentation.forEach(x => {
                x.field = x.id
                x.module = 'Documentation'
                x.value = this.getResponsibility('Documentation', x.id)
            })
            this.loadingDocumentation = false
        },
        async getRelationshipResponsibilities() {
            this.responsibilities = await this.$API.getRelationshipResponsibilities(this.selectedCustomer.reference)
            this.modules.forEach(x => {
                x.submodules.forEach(y => {
                    y.fields.forEach(z => {
                        z.value = this.getResponsibility(x.name, z.field)
                    })
                })
            })
        },
        getResponsibility(module, field) {
            let result = this.responsibilities.find(x => x.module === module && x.field === field)
            if (result) {
                return result.organisationId
            } else {
                return null
            }

        }
    }
}
</script>